/* eslint-disable no-unused-vars */
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

// render - pages
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const Ikrar = Loadable(lazy(() => import('pages/extra-pages/ikrar')));
const AddIkrar = Loadable(lazy(() => import('pages/extra-pages/ikrar/addIkrar')));
const UpdateIkrar = Loadable(lazy(() => import('pages/extra-pages/ikrar/updateIkrar')));
const IkrarDetails = Loadable(lazy(() => import('pages/extra-pages/ikrar/details')));
const UploadProof = Loadable(lazy(() => import('pages/extra-pages/ikrar/details/uploadProof')));
const ZakatProgram = Loadable(lazy(() => import('pages/extra-pages/zakat/program')));
const AddZakatProgram = Loadable(lazy(() => import('pages/extra-pages/zakat/program/addZakatProgram')));
const UpdateZakatProgram = Loadable(lazy(() => import('pages/extra-pages/zakat/program/updateZakatProgram')));
const ActivityProgramZakat = Loadable(lazy(() => import('pages/extra-pages/zakat/program/activity')));
const AddZakatProgramActivity = Loadable(lazy(() => import('pages/extra-pages/zakat/program/activity/addActivity')));
const UpdateZakatProgramActivity = Loadable(lazy(() => import('pages/extra-pages/zakat/program/activity/updateActivity')));
const User = Loadable(lazy(() => import('pages/extra-pages/user')));
const AddUser = Loadable(lazy(() => import('pages/extra-pages/user/addUser')));
const UpdateUser = Loadable(lazy(() => import('pages/extra-pages/user/updateUser')));
const SatuanKerja = Loadable(lazy(() => import('pages/extra-pages/satuanKerja')));
const AddSatker = Loadable(lazy(() => import('pages/extra-pages/satuanKerja/addSatker')));
const UpdateSatker = Loadable(lazy(() => import('pages/extra-pages/satuanKerja/updateSatker')));
const ZakatSubmission = Loadable(lazy(() => import('pages/extra-pages/submission')));
const AddSubmission = Loadable(lazy(() => import('pages/extra-pages/submission/addSubmission')));
const UpdateSubmission = Loadable(lazy(() => import('pages/extra-pages/submission/updateSubmission')));
const SubmissionDetails = Loadable(lazy(() => import('pages/extra-pages/submission/details')));
const ZakatReception = Loadable(lazy(() => import('pages/extra-pages/reception')));
const ReceptionDetails = Loadable(lazy(() => import('pages/extra-pages/reception/details')));
const AddRealizeReception = Loadable(lazy(() => import('pages/extra-pages/reception/realize')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: 'satker',
      element: <SatuanKerja />
    },
    {
      path: '/satker/add',
      element: <AddSatker />
    },
    {
      path: '/satker/update/:id',
      element: <UpdateSatker />
    },
    {
      path: 'users',
      element: <User />
    },
    {
      path: 'users/add',
      element: <AddUser />
    },
    {
      path: 'users/update',
      element: <UpdateUser />
    },
    {
      path: 'zakat/program',
      element: <ZakatProgram />
    },
    {
      path: 'zakat/program/add',
      element: <AddZakatProgram />
    },
    {
      path: 'zakat/program/update/:id',
      element: <UpdateZakatProgram />
    },
    {
      path: 'zakat/program/activity',
      element: <ActivityProgramZakat />
    },
    {
      path: 'zakat/program/activity/:id',
      element: <ActivityProgramZakat />
    },
    {
      path: 'zakat/program/activity/add/:id',
      element: <AddZakatProgramActivity />
    },
    {
      path: 'zakat/program/activity/update/:id',
      element: <UpdateZakatProgramActivity />
    },
    {
      path: 'ikrar',
      element: <Ikrar />
    },
    {
      path: 'ikrar/details/:id',
      element: <IkrarDetails />
    },
    {
      path: '/ikrar/details/upload/:id',
      element: <UploadProof />
    },
    {
      path: '/ikrar/add',
      element: <AddIkrar />
    },
    {
      path: '/ikrar/update/:id',
      element: <UpdateIkrar />
    },
    {
      path: '/submission',
      element: <ZakatSubmission />
    },
    {
      path: '/submission/details/:id',
      element: <SubmissionDetails />
    },
    {
      path: '/submission/add',
      element: <AddSubmission />
    },
    {
      path: '/submission/update/:id',
      element: <UpdateSubmission />
    },
    {
      path: '/reception',
      element: <ZakatReception />
    },
    {
      path: '/reception/details/:id',
      element: <ReceptionDetails />
    },
    {
      path: '/reception/realize/:id',
      element: <AddRealizeReception />
    }
  ]
};

export default MainRoutes;
