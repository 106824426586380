import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: localStorage.getItem('token') ? true : false,
  user: JSON.parse(localStorage.getItem('user')) || null
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action) {
      const { user, token } = action.payload;
      state.isAuthenticated = true;
      state.user = user;

      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
    },
    logout(state) {
      state.isAuthenticated = false;
      state.user = null;

      localStorage.removeItem('user');
      localStorage.removeItem('token');
    }
  }
});

export default auth.reducer;

export const { login, logout } = auth.actions;
