// assets
import {
  SafetyOutlined,
  QuestionOutlined,
  TeamOutlined,
  SolutionOutlined,
  FileDoneOutlined,
  UploadOutlined,
  CheckOutlined,
  ChromeOutlined
} from '@ant-design/icons';

// icons
const icons = {
  SafetyOutlined,
  QuestionOutlined,
  TeamOutlined,
  SolutionOutlined,
  FileDoneOutlined,
  UploadOutlined,
  CheckOutlined,
  ChromeOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const zakat = {
  id: 'zakat_id',
  title: 'Pengajuan & Penerimaan',
  type: 'group',
  children: [
    {
      id: 'submission',
      // type: 'item',
      title: 'Pengajuan',
      url: '/submission',
      icon: icons.UploadOutlined,
      children: [
        {
          id: 'ikrarform',
          // type: 'item',
          title: 'Tambah Pengajuan',
          url: '/submission/create',
          icon: icons.ChromeOutlined
        }
      ]
    },
    {
      id: 'acceptance',
      title: 'Penerimaan',
      url: '/reception',
      icon: icons.CheckOutlined,
      children: [
        {
          id: 'form',
          // type: 'item',
          title: 'Tambah Penerimaan',
          url: '/submission/create',
          icon: icons.ChromeOutlined
        }
      ]
    },
    {
      id: 'zakat',
      // type: 'item',
      title: 'Program & Kegiatan',
      url: '/zakat/program',
      icon: icons.FileDoneOutlined
    }
  ]
};

export default zakat;
