/* eslint-disable no-unused-vars */
// project import
// import pages from './pages';
import loginData from 'lib/data/user/login';
import dashboard from './dashboard';
import ikrar from './ikrar';
import zakat from './zakat';
import { USER } from 'lib/constant/user';

const menuItems = {
  items: [loginData?.role_id === USER.ROLE_SATKER ? null : dashboard, ikrar, loginData?.role_id === USER.ROLE_SATKER ? null : zakat].filter(
    Boolean
  )
};

export default menuItems;
