// assets
import { SafetyOutlined, QuestionOutlined, TeamOutlined, SolutionOutlined, FileDoneOutlined } from '@ant-design/icons';
import loginData from 'lib/data/user/login';
import { USER } from 'lib/constant/user';

// icons
const icons = {
  SafetyOutlined,
  QuestionOutlined,
  TeamOutlined,
  SolutionOutlined,
  FileDoneOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const ikrar = {
  id: 'ikrar_id',
  title: 'Ikrar',
  type: 'group',
  children: [
    {
      id: 'ikrar',
      // type: 'item',
      title: 'Ikrar',
      url: '/ikrar',
      icon: icons.SafetyOutlined
    },
    loginData?.role_id === USER.ROLE_ADMIN
      ? {
          id: 'satuan-kerja',
          // type: 'item',
          title: 'Satuan Kerja',
          url: '/satker',
          icon: icons.SolutionOutlined
        }
      : null
  ].filter(Boolean)
};

export default ikrar;
